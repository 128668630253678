.chartLine,
.outOfBounds {
  stroke-width: 3;
  stroke: var(--clr-neutral-500);
  fill: transparent;
}

.chartLine.highlight {
  stroke-width: 7;
}

.chartPoint {
  fill: var(--clr-neutral-500);
}

.blue {
  stroke: var(--clr-blue-500);
  fill: var(--clr-blue-500);
}

.red {
  stroke: var(--clr-red-500);
  fill: var(--clr-red-500);
}

.green {
  stroke: var(--clr-green-500);
  fill: var(--clr-green-500);
}

.orange {
  stroke: var(--clr-orange-500);
  fill: var(--clr-orange-500);
}

.purple {
  stroke: var(--clr-purple-500);
  fill: var(--clr-purple-500);
}

.yellow {
  stroke: var(--clr-yellow-500);
  fill: var(--clr-yellow-500);
}

.dashed {
  stroke-dasharray: 5, 5;
}

.chartLine.blue,
.chartLine.red,
.chartLine.green,
.chartLine.orange,
.chartLine.purple,
.chartLine.yellow,
.outOfBounds {
  fill: transparent;
}
