.label {
  display: flex;
  width: var(--width-label-base);
  flex-grow: 0;
  flex-shrink: 0;
  margin: var(--padding-label-v) 0;
  justify-content: flex-end;
}

.shortLabel {
  width: var(--width-label-short);
}

.inputLabel {
  margin: var(--padding-input-label-v) 0;
}

.checkboxLabel {
  width: unset;
}
