.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: var(--gap-list);
  padding-bottom: var(--padding-section-bottom);
}

.row {
  flex-direction: row;
}
