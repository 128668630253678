.gridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-base);
}

.gridCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: var(--border-radius-card);
  padding: var(--padding-card);
  gap: var(--gap-base);
}
