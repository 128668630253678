.errorPoint {
  stroke-width: 2;
  stroke: var(--clr-neutral-500);
}

.AC,
.Selectivity {
  stroke: var(--clr-blue-500);
}

.BC,
.BCx2 {
  stroke: var(--clr-red-500);
}
