.userBadge {
  display: flex;
  gap: var(--gap-xs);
}

.userBadgeButton {
  border: 0;
  border-radius: var(--rounded-button-full);
  background-color: transparent;
  cursor: pointer;
}
