.selectionList {
  display: flex;
  flex-direction: column;
  gap: var(--gap-list);
}

.selectionList.selected {
  max-height: var(--height-textarea-triple);
  overflow: auto;
}

.selectionList.options {
  max-height: var(--height-textarea-triple);
  overflow: auto;
}
