.axisLabel {
  position: absolute;
  display: flex;
  font-size: var(--font-size-axis-label);
  line-height: var(--line-height-axis-label);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
