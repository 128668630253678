.levelIndicators {
  width: 100px;
  height: var(--height-level-slider);
  position: relative;
}

.indicatorContainer {
  width: 100%;
  height: var(--height-slider-thumb);
  position: absolute;
  right: var(--gap-base);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
}

.hidden {
  display: none;
}
