.table {
  width: fit-content;
  background-color: var(--border-color-table);
  border-collapse: separate;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.tableHader {
  display: flex;
  flex-grow: 1;
}

.tableHaderRow {
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid var(--border-color-table-header);
}

.tableRow {
  display: flex;
  flex-grow: 1;
}

.cell {
  text-align: start;
  padding: var(--spacing-1);
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--font-size-table-row);
  background-color: var(--background-color-table-row);
  padding-right: var(--padding-table-r);
  white-space: pre-line;
}

.cellContainer {
  display: flex;
  column-gap: var(--gap-base);
  flex-wrap: wrap;
}

.header.cell {
  white-space: initial;
  font-size: var(--font-size-table-header);
  background-color: var(--background-color-table-header);
  color: var(--color-table-header);
  display: flex;
  flex-grow: 1;
}

.headerCellContainer {
  display: flex;
  gap: var(--gap-base);
  white-space: nowrap;
}
