input[type="range"].slider {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}
input[type="range"].slider::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
}

input[type="range"].slider {
  cursor: pointer;
  width: 100%;
  height: var(--height-slider);
}

input[type="range"].vertical {
  writing-mode: vertical-lr;
  width: var(--height-slider);
  height: var(--height-level-slider);
}

/* Track: webkit */
input[type="range"].slider::-webkit-slider-runnable-track {
  height: var(--height-slider-track);
  background: var(--background-color-button-base);
  border-radius: var(--height-slider-track);
}

input[type="range"].vertical::-webkit-slider-runnable-track {
  width: var(--height-slider-track);
}

/* Track: Firefox */
input[type="range"].slider::-moz-range-track {
  height: var(--height-slider-track);
  background: var(--background-color-button-base);
  border-radius: var(--height-slider-track);
}

input[type="range"].vertical::-moz-range-track {
  width: var(--height-slider-track);
}

/* Thumb: webkit */
input[type="range"].slider::-webkit-slider-thumb {
  height: var(--height-slider-thumb);
  width: var(--height-slider-thumb);
  background-color: var(--color-button);
  border-radius: var(--height-slider-thumb);
  margin-top: calc(
    (var(--height-slider-track) - var(--height-slider-thumb)) / 2
  );
}

input[type="range"].vertical::-webkit-slider-thumb {
  margin-top: 0;
  margin-left: calc(
    (var(--height-slider-track) - var(--height-slider-thumb)) / 2
  );
}

/* Thumb: Firefox */
input[type="range"].slider::-moz-range-thumb {
  height: var(--height-slider-thumb);
  width: var(--height-slider-thumb);
  background-color: var(--color-button);
  border-radius: var(--height-slider-thumb);
  margin-top: calc(
    (var(--height-slider-track) - var(--height-slider-thumb)) / 2
  );
}

input[type="range"].vertical::-moz-range-thumb {
  margin-top: 0;
  margin-left: calc(
    (var(--height-slider-track) - var(--height-slider-thumb)) / 2
  );
}

input[type="range"].slider.blue::-webkit-slider-thumb {
  background-color: var(--clr-blue-500);
}

input[type="range"].slider.red::-webkit-slider-thumb {
  background-color: var(--clr-red-500);
}

input[type="range"].slider.blue::-moz-range-thumb {
  background-color: var(--clr-blue-500);
}

input[type="range"].slider.red::-moz-range-thumb {
  background-color: var(--clr-red-500);
}
