.dialogFooter {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  gap: var(--gap-modal-footer);
  padding-top: var(--padding-dialog-footer);
  justify-content: flex-end;
}

.pageFooter {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  gap: var(--gap-modal-footer);
  padding-top: var(--padding-page-footer);
  justify-content: flex-end;
}

.centered {
  justify-content: center;
}
