.topBar {
  position: fixed;
  top: 0;
  left: calc(var(--sidebar-width-collapsed) + var(--padding-topbar));
  right: var(--padding-topbar);
  height: var(--height-topbar);
  display: flex;
  align-items: center;
  padding: 0 var(--padding-topbar);
  background-color: var(--background-color-base);
  border-bottom: 1px solid var(--clr-neutral-100);
  z-index: 1;
  gap: var(--gap-base);
}

.sideNavExpanded {
  left: calc(var(--sidebar-width) + var(--padding-topbar));
}

/*
--padding-topbar
*/
