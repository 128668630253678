.userRoundel {
  width: var(--line-height-meta);
  height: var(--line-height-meta);
  border-radius: var(--line-height-meta);
  overflow: hidden;
  padding: 0;
  color: var(--color-roundel);
  font-size: var(--font-size-roundel);
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  background-color: var(--background-color-roundel);
}

.empty {
  background-color: var(--background-color-button-base);
}

.large {
  width: var(--height-button);
  height: var(--height-button);
  border-radius: var(--rounded-button-full);
  font-size: var(--font-size-base);
}
